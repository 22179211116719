import { inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { Country } from '@features/country/domain';
import * as Merchant from '@taager-experience-shared/merchant';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { Column, Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ProvinceZoneDistrictModel, ProvinceZoneModel } from 'src/app/core/domain/provinces.model';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import * as XLSX from 'xlsx';
import { PREPAID_ORDERS } from '../constants';
import { PREPAID_COLUMN_OPTIONS } from '../constants/cart';
import { InvalidHeadersError } from '../error-classes/invalid-headers.error';
import Province = Merchant.com.taager.experience.provinces.domain.entity.Province;

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private _checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  extractToExcel(array: any, fileName: string): void {
    const separator = ',';
    const keys = Object.keys(array[0]);
    let csvContent = '\uFEFF';
    csvContent += `${keys.join(separator)}\n${array
      .map((row: any) =>
        keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""').replace(',', ' ');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator),
      )
      .join('\n')}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // @ts-ignore
    if (navigator.msSaveBlob) {
      // IE 10+
      // @ts-ignore
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  private _createWorksheetColumns(
    hasPrepaidFeature: boolean,
    shouldShowDetailedAddress?: boolean,
    shouldShowOrderPlacementColumn?: boolean,
  ): Array<Partial<Column>> {
    const columns: Array<Partial<Column>> = [];
    const arabicTranslations: { [key: string]: string } = {
      RECEIVER_NAME: 'اسم_العميل',
      PRODUCT_NAME: 'اسم_المنتج',
      FACEBOOK_PAGE: 'اسم_صفحة_الفيسبوك',
      COUNTRY: 'البلد',
      STREET_NAME: 'العنوان',
      PROVINCE: 'المحافظة',
      ...(shouldShowDetailedAddress ? { ZONE: 'المنطقة - (اختياري)' } : {}),
      ...(shouldShowDetailedAddress ? { DISTRICT: 'الحي- (اختياري)' } : {}),
      PHONE_NUMBER: 'رقم_الهاتف',
      PHONE_NUMBER2: 'رقم_الهاتف2',
      PRODUCT_NEW_PRICE: 'سعر_المنتج',
      PRODUCT_QTY: 'الكمية',
      PRODUCT_ID: 'كود_المنتج',
      FACEBOOK_PAGE_LINK: 'لينك_الصفحة',
      NOTES: 'ملاحظات',
      IS_PRE_PAID: 'مسبق الدفع',
      COLOR: 'لون_المنتج',
      SIZE: 'مقاس_المنتج',
      ...(shouldShowOrderPlacementColumn ? { ORDER_PLACEMENT_TIME: 'وقت_طلب_المنتج' } : {}),
    };
    const englishTranslations: { [key: string]: string } = {
      RECEIVER_NAME: 'Customer Name',
      PRODUCT_NAME: 'Product Name',
      FACEBOOK_PAGE: 'Facebook Page',
      COUNTRY: 'Country',
      STREET_NAME: 'Address',
      PROVINCE: 'Province',
      ...(shouldShowDetailedAddress ? { ZONE: 'Zone' } : {}),
      ...(shouldShowDetailedAddress ? { DISTRICT: 'District' } : {}),
      PHONE_NUMBER: 'Phone Number',
      PHONE_NUMBER2: 'Phone Number 2',
      PRODUCT_NEW_PRICE: 'Product Price',
      PRODUCT_QTY: 'Quantity',
      PRODUCT_ID: 'Product Id',
      FACEBOOK_PAGE_LINK: 'Facebook Page Link',
      NOTES: 'Notes',
      IS_PRE_PAID: 'Pre Paid',
      COLOR: 'Product Color',
      SIZE: 'Product Size',
      ...(shouldShowOrderPlacementColumn ? { ORDER_PLACEMENT_TIME: 'Order Placement Time' } : {}),
    };
    const keyToTranslationString: { [key: string]: string } = {
      code: 'PRODUCT_ID',
      name: 'PRODUCT_NAME',
      price: 'PRODUCT_NEW_PRICE',
      quantity: 'PRODUCT_QTY',
      clientName: 'RECEIVER_NAME',
      province: 'PROVINCE',
      ...(shouldShowDetailedAddress ? { zone: 'ZONE' } : {}),
      ...(shouldShowDetailedAddress ? { district: 'DISTRICT' } : {}),
      address: 'STREET_NAME',
      phone1: 'PHONE_NUMBER',
      phone2: 'PHONE_NUMBER2',
      notes: 'NOTES',
      pageName: 'FACEBOOK_PAGE',
      pageLink: 'FACEBOOK_PAGE_LINK',
      country: 'COUNTRY',
      color: 'COLOR',
      size: 'SIZE',
      ...(hasPrepaidFeature ? { prepaid: 'IS_PRE_PAID' } : {}),
      ...(shouldShowOrderPlacementColumn ? { orderPlacementTime: 'ORDER_PLACEMENT_TIME' } : {}),
    };
    for (const key in keyToTranslationString) {
      if (key in keyToTranslationString) {
        const header = `${arabicTranslations[keyToTranslationString[key]]} (${
          englishTranslations[keyToTranslationString[key]]
        })`;
        columns.push({ header, key, width: header.length });
      }
    }
    return columns;
  }

  exportCartToExcel(
    array: any,
    fileName: string,
    provinces: Province[],
    selectedCountry: Country,
    featureFlag: { shouldShowDetailedAddress: boolean; shouldShowOrderPlacementColumn: boolean },
    zones: ProvinceZoneModel[],
    districts: ProvinceZoneDistrictModel[],
  ): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(fileName);
    const hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);
    const shouldShowDetailedAddress = featureFlag?.shouldShowDetailedAddress;
    const shouldShowOrderPlacementColumn = featureFlag?.shouldShowOrderPlacementColumn;
    const workSheetColumns = this._createWorksheetColumns(
      hasPrepaidFeature,
      shouldShowDetailedAddress,
      shouldShowOrderPlacementColumn,
    );
    worksheet.columns = workSheetColumns;

    const provinceLength = provinces?.length;
    const zoneLength = zones?.length;
    const districtLength = districts?.length;

    const worksheetAddressData = workbook.addWorksheet('AddressData', { state: 'hidden' }); // for storing list of zones & districts

    let provinceNames: string;
    const headers = [{ key: 'province', width: 15 }];
    if (shouldShowDetailedAddress) {
      headers.push({ key: 'zone', width: 15 }, { key: 'district', width: 15 });
      let zoneNames: string;
      worksheetAddressData.columns = headers;
      for (let i = 0; i <= districtLength; i++) {
        const districtNames: string = districts[i]?.districtName.arabic;
        zoneNames = zones[i]?.zoneName.arabic;
        if (i <= provinces.length) {
          provinceNames = provinces[i]?.location;
          worksheetAddressData.insertRow(i + 1, {
            province: provinceNames,
            zone: zoneNames,
            district: districtNames,
          });
        } else if (i <= zones.length) {
          worksheetAddressData.insertRow(i + 1, { zone: zoneNames, district: districtNames });
        } else {
          worksheetAddressData.insertRow(i + 1, { district: districtNames });
        }
      }
    }
    if (!shouldShowDetailedAddress) {
      worksheetAddressData.columns = headers;
      for (let i = 0; i <= provinceLength; i++) {
        provinceNames = provinces[i]?.location;
        worksheetAddressData.insertRow(i + 1, { province: provinceNames });
      }
    }
    if (array?.length) {
      array.forEach((element: any, index: number) => {
        const colorHex = element?.attributes?.filter(
          (attribute: any) => attribute.type === 'color',
        )[0]?.value;
        const rowProperties = {
          code: element.pid || element.prodId,
          name: element.productName || element.name,
          price: element?.newPrice || element.productPrice || element.desiredPrice,
          quantity: element.quantity || element.qty,
          clientName: '',
          province: '',
          ...(shouldShowDetailedAddress ? { zone: '' } : {}),
          ...(shouldShowDetailedAddress ? { district: '' } : {}),
          address: '',
          phone1: 0,
          phone2: '',
          notes: 'notes',
          pageName: 'اسم_صفحة_الفيسبوك',
          pageLink: 'لينك_الصفحة',
          country: country.name,
          color: colorHex ? variants.getColorByHexCode(colorHex).arabicName : '',
          size: element?.attributes?.find((attr: any) => attr.type === 'size')?.value || '',
          ...(shouldShowOrderPlacementColumn ? { orderPlacementTime: '' } : {}),
        };
        worksheet.getColumn('phone1').numFmt = '0';
        worksheet.getColumn('phone2').numFmt = '0';
        worksheet.addRow(rowProperties);

        worksheet.getCell(`F${index + 2}`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: [`AddressData!A1:A${provinceLength}`],
        };
        if (shouldShowDetailedAddress) {
          worksheet.getCell(`G${index + 2}`).dataValidation = {
            type: 'list',
            allowBlank: false,
            formulae: [`AddressData!B1:B${zoneLength}`],
          };
          worksheet.getCell(`H${index + 2}`).dataValidation = {
            type: 'list',
            allowBlank: false,
            formulae: [`AddressData!C1:C${districtLength}`],
          };
        }
      });
    } else {
      let phoneNumberValidation = '';
      if (country.is('EGY')) phoneNumberValidation = 'يجب ان يبدأ ب 1 ويكون عدد الأرقام ١٠';
      if (country.is('UAE')) phoneNumberValidation = 'مكون من أرقام فقط بدون أي مسافات';

      worksheet.addRow({
        code: 'ادخل كود المنتج (خانة مطلوبة)',
        name: 'ادخل اسم المنتج (خانة مطلوبة)',
        price: 'سعر المنتج لابد الا يقل عن السعر الأصلي',
        quantity: 'ارقام فقط (خانة مطلوبة)',
        clientName: 'يجب الا يزيد عن 50 حرف (خانة مطلوبة)',
        province: 'خانة مطلوبة',
        ...(featureFlag?.shouldShowDetailedAddress ? { zone: 'المنطقة - (اختياري)' } : {}),
        ...(featureFlag?.shouldShowDetailedAddress ? { district: 'الحي- (اختياري)' } : {}),
        address: 'خانة مطلوبة',
        phone1: phoneNumberValidation,
        phone2: 0,
        notes: 'notes',
        pageName: 'اسم_صفحة_الفيسبوك',
        pageLink: 'لينك_الصفحة',
        country: country.name,
        color: '',
        size: '',
      });
      worksheet.getColumn('phone1').numFmt = '0';
      worksheet.getColumn('phone2').numFmt = '0';
      worksheet.getCell(`F2`).dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: [`AddressData!A1:A${provinceLength}`],
      };
      if (shouldShowDetailedAddress) {
        worksheet.getCell(`G2`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: [`AddressData!B1:B${zoneLength}`],
        };
        worksheet.getCell(`H2`).dataValidation = {
          type: 'list',
          allowBlank: false,
          formulae: [`AddressData!C1:C${districtLength}`],
        };
      }
    }

    if (hasPrepaidFeature) {
      worksheet.getColumn('prepaid').eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          cell.value = PREPAID_COLUMN_OPTIONS.cash;
          cell.dataValidation = {
            type: 'list',
            formulae: [`"${PREPAID_COLUMN_OPTIONS.cash}, ${PREPAID_COLUMN_OPTIONS.prepaid}"`],
          };
        }
      });
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `${fileName}.xlsx`);
    });
  }

  getUploadedOrdersFromExcelFile(file: any): Promise<Array<Array<string>>> {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);

    return new Promise((resolve, reject) => {
      reader.onload = (event: any) => {
        const worksheet = this._getWorksheetFromTargetFileBinaryString(event);

        const lines = this._getLinesFromWorksheet(worksheet);

        const headers = lines[0].filter((header) => header);

        const hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);

        if (headers.length < (hasPrepaidFeature ? 16 : 12)) {
          reject(new InvalidHeadersError());
        }

        resolve(lines.slice(1));
      };
    });
  }

  private _getWorksheetFromTargetFileBinaryString(readEvent: {
    target: { result: string };
  }): XLSX.WorkSheet {
    const binarystr: string = readEvent.target.result;
    const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
    const wsname: string = wb.SheetNames[0];
    return wb.Sheets[wsname];
  }

  private _getLinesFromWorksheet(worksheet: XLSX.WorkSheet): Array<Array<string>> {
    let row;
    let rowNum;
    let colNum;

    const lines: any = [];

    const range = XLSX.utils.decode_range(worksheet['!ref']!);
    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c + 1; colNum++) {
        const nextCell = worksheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
        if (typeof nextCell !== 'undefined') {
          row.push(nextCell.w);
        } else {
          row.push(null);
        }
      }
      lines.push(row);
    }
    return lines;
  }
}
